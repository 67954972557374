@use "sass:map";

@import '../../scss/variables';

@media (max-width: 576px) { /* iPhone width */
    .navitem-text {
      font-size: 0.7rem; /* Adjust as needed */
    }
  }
  
  @media (min-width: 577px) { /* Anything above iPhone width */
    .navitem-text {
      font-size: 1.3rem; /* Adjust as needed */
    }
  }